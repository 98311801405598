import React, { useState, useEffect, useRef, useMemo } from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { graphql, navigate } from "gatsby";
import { GatsbyImage as Image } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";
import { uniqBy } from "lodash-es";
import useMedia from "../../hooks/useMedia.js";
import Layout from "../../components/Layout/Layout";
import { Video, TextSplit } from "components";
import { Wrapper } from "../../components/globalElements";
import {
	CrumbLink,
	Square,
	ProductTemplateContainer,
	ProductTemplateWrapper,
	ProductWrapper1
} from "./ProductTemplate.styles";
import CaretRight from "../../assets/svg/caret-right.svg";
import { AiOutlineCheck, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Dropdown from "react-dropdown";
import { getCurrentUser } from "../../utils/firebase/firebase.utils";
import { ImSpinner } from "react-icons/im";

import "react-dropdown/style.css";
import axios from "axios";

const Arrow = ({ type, onClick, isEdge }) => {
	const pointer =
		type === consts.PREV ? (
			<CaretRight style={{ rotate: "180deg" }} />
		) : (
			<CaretRight />
		);
	return (
		<button onClick={onClick} disabled={isEdge}>
			{pointer}
		</button>
	);
};

const Refin = ({ metafields }) => {
	const { long_description, product_video, title } = metafields.reduce(
		(obj, item) => {
			obj[item.key] = item.value;
			return obj;
		},
		{}
	);

	return (
		<Wrapper style={{ marginBottom: "5rem" }}>
			{long_description && title && (
				<TextSplit>
					<h2>{title}</h2>
					<div
						dangerouslySetInnerHTML={{ __html: long_description }}
					/>
				</TextSplit>
			)}
			<Video src={product_video} />
		</Wrapper>
	);
};

const ProductCircles = ({ currentOption, colors, onClick }) => {
	const start =
		colors.findIndex((color) => color?.id === currentOption?.id) !== -1
			? colors.findIndex((color) => color?.id === currentOption?.id)
			: 0;

	return (
		<div className="squares-container">
			{colors
				.map((color) => (
					<div key={color.id} className="circles-container__wrapper">
						<Square
							bkg={color.image}
							onClick={() => onClick(color)}
						/>
					</div>
				))
				.slice(start, start + 4)}
		</div>
	);
};

const Selector = ({ options, onChange, value }) => {
	return (
		<Dropdown
			value={value}
			options={options}
			onChange={onChange}
			baseClassName="custom__selector"
			arrowClosed={<AiOutlineDown />}
			arrowOpen={<AiOutlineUp />}
		/>
	);
};

const extractProductId = (product) => {
	const regex = /(?<=AMALFI)\d+/;
	const id = product?.title?.match(regex);
	return id ? id[0] : null;
};

const fetchVulcanoProduct = async (product, id) => {
	const response = await axios.post("/.netlify/functions/cin7-stock", [id]);

	const vulcanoProduct = response?.data;

	const updatedProduct = vulcanoProduct[0]
		? {
				...product,
				totalInventory: Math.floor(vulcanoProduct[0]?.available)
		  }
		: product;

	return updatedProduct;
};

const Product = ({ data }) => {
	// let product = data.shopifyProduct;
	const [isLoading, setIsLoading] = useState();
	const [product, setProduct] = useState(data.shopifyProduct);
	const carousel = useRef(null);
	const { isTablet } = useMedia();
	const { search, pathname } = useLocation();
	const variants = product?.variants;
	const collection = data.shopifyProduct.collections[0];
	const [shrink, setShrink] = useState(false);
	const [currentOption, setCurrentOption] = useState([]);
	const [productDetails, setProductDetails] = useState(currentOption[0]);
	// const catalogue = product.metafields.find(({ key }) => key === "catalogue");
	const isRefin = product.metafields.find(
		({ key }) => key === "show_content"
	);

	const colors = useMemo(() => {
		if (!variants) return [];

		const allVariants = variants.map((v) => ({
			image: v?.image?.gatsbyImageData?.images.fallback.src,
			value: v?.selectedOptions[0].value,
			id: v?.id,
			shopifyId: v.image?.id
		}));

		return uniqBy(allVariants, "value");
	}, [variants]);

	const sizeOptions = useMemo(() => {
		return productDetails?.filter(({ size }) => size) || [];
	}, [productDetails]);

	const [selectedColor, setSelectedColor] = useState(colors[0]?.id);
	const [selectedSize, setSelectedSize] = useState(null);

	useEffect(() => {
		changeImageSlide(currentOption);
	}, [currentOption]);

	useEffect(() => {
		if (productDetails?.[0]?.size) setSelectedSize(productDetails[0].size);
	}, [productDetails]);

	const onVariantChange = (option) => {
		setCurrentOption(option);
	};

	useEffect(() => {
		let option = colors.find((color) => color.id === selectedColor);
		const { variants } = product;

		const productDetails = variants
			.filter((variant) => {
				const found = variant.selectedOptions.find((selectedOption) => {
					return (
						selectedOption.name === "Colour" &&
						selectedOption.value.toLowerCase() ===
							option.value.toLowerCase()
					);
				});
				return found ? variant : null;
			})
			.map(({ sku, price, inventoryQuantity, selectedOptions }) => {
				const options = selectedOptions.reduce((acc, option) => {
					acc[option.name.toLowerCase()] = option.value;
					return acc;
				}, {});

				return {
					...options,
					sku,
					price,
					inventoryQuantity
				};
			});

		setProductDetails(productDetails);
	}, [selectedColor]);

	const changeImageSlide = (circle) => {
		const children = carousel?.current?.props?.children || [];
		let chosenIndex;
		children.forEach((slide, i) => {
			if (slide.props.shopifyId === circle.shopifyId) {
				chosenIndex = i;
				carousel.current.goTo(chosenIndex);
			}
		});
	};

	useEffect(() => {
		setShrink(isTablet);
	}, [isTablet]);

	useEffect(() => {
		const id = extractProductId(product);

		if (id && product?.vendor === "Vulcano") {
			setIsLoading(true);
			fetchVulcanoProduct(product, id).then((updatedProduct) => {
				setProduct(updatedProduct);
				setIsLoading(false);
			});
		}
	}, []);

	return (
		<Layout
			title={`${product.title} | ${collection.title}`}
			description={product.description}
			keywords={product.tags}
			fullScreen={shrink}
		>
			<ProductTemplateContainer>
				<ProductTemplateWrapper>
					<div className="breadcrumb">
						<CrumbLink to="/tiles"> Back to Tiles</CrumbLink> {"> "}
						<CrumbLink to={`/tiles/${collection?.handle}`}>
							{collection.title}
						</CrumbLink>{" "}
						{"> "}
						<CrumbLink active={true} to={pathname + search}>
							{product.title}
						</CrumbLink>
					</div>
				</ProductTemplateWrapper>
				<ProductTemplateWrapper>
					<ProductWrapper1 isRefin={isRefin} width="90%">
						<Carousel
							renderArrow={Arrow}
							outerSpacing={0}
							ref={carousel}
							enableSwipe={true}
							enableMouseSwipe={false}
							pagination={false}
							onNextEnd={({ index }) => {
								let resetTimeout;
								clearTimeout(resetTimeout);
								if (index + 1 === product.images.length) {
									resetTimeout = setTimeout(() => {
										carousel.current.goTo(0);
									}, 1500);
								}
							}}
						>
							{product?.images?.map((image) => (
								<Image
									key={image.id}
									shopifyId={image?.shopifyId}
									url={
										image.gatsbyImageData.images.fallback
											.src
									}
									image={image.gatsbyImageData}
									alt={
										image.altText === null
											? "slider image"
											: image.altText
									}
								/>
							))}
						</Carousel>
						<ProductCircles
							colors={colors}
							onClick={onVariantChange}
							currentOption={currentOption}
						/>
					</ProductWrapper1>
					<ProductWrapper1>
						<div
							className="heading"
							style={{
								display: "flex",
								justifyContent: "space-between"
							}}
						>
							<h1 className="heading__title">{product.title}</h1>
							{/* {getCurrentUser() && (
								<h1 className="heading__title">
									$
									{productDetails?.find(
										({ size }) => size === selectedSize
									)?.price || "N/A"}{" "}
									{
										product.priceRangeV2.minVariantPrice
											.currencyCode
									}
								</h1>
							)} */}
						</div>
						{product?.descriptionHtml ? (
							<div
								className="heading__description"
								dangerouslySetInnerHTML={{
									__html: product.descriptionHtml
								}}
							/>
						) : (
							<p className="heading__description">
								{product.description}
							</p>
						)}
						<div className="product__details">
							<h4>
								Product Code:{" "}
								<span className="hightlight__text">
									{productDetails?.find(
										({ size }) => size === selectedSize
									)?.sku ||
										variants[0]?.sku ||
										"N/A"}
								</span>
							</h4>

							{getCurrentUser() &&
								(product?.vendor === "Vulcano" ? (
									isLoading ? (
										<ImSpinner fill="#3a67b1" />
									) : product?.totalInventory > 0 ? (
										<div className="available__items">
											<AiOutlineCheck
												fill="#fff"
												className="checkmark"
											/>
											<h4 className="hightlight__text">
												{product?.totalInventory +
													" Available"}
											</h4>
										</div>
									) : (
										<span className="outofstock__text">
											Out of Stock
										</span>
									)
								) : product?.totalInventory > 0 ? (
									<div className="available__items">
										<AiOutlineCheck
											fill="#fff"
											className="checkmark"
										/>
										<h4 className="hightlight__text">
											{productDetails?.find(
												({ size }) =>
													size === selectedSize
											)?.inventoryQuantity + " Available"}
										</h4>
									</div>
								) : product?.variants[0]?.availableForSale ? (
									<div className="available__items">
										<AiOutlineCheck
											fill="#fff"
											className="checkmark"
										/>
										<h4 className="hightlight__text">
											Available
										</h4>
									</div>
								) : (
									<span className="outofstock__text">
										Out of Stock
									</span>
								))}
						</div>

						{productDetails?.length > 0 && (
							<>
								<div className="selector__container">
									<label>Color</label>
									<Selector
										value={selectedColor}
										options={colors.map((color) => ({
											label: color?.value,
											value: color?.id
										}))}
										onChange={(color) => {
											setSelectedColor(color?.value);
											onVariantChange(
												colors?.find(
													({ id }) =>
														id === color?.value
												)
											);
										}}
									/>
								</div>

								{sizeOptions.length ? (
									<div className="selector__container">
										<label>Size</label>
										<Selector
											value={selectedSize}
											options={sizeOptions.map(
												({ size }) => ({
													label: size,
													value: size
												})
											)}
											onChange={({ value }) =>
												setSelectedSize(value)
											}
										/>
									</div>
								) : null}
							</>
						)}

						<div className="button__container">
							{/* <a
								className="button-filled"
								href={catalogue?.value}
								target="_blank"
								rel="noreferrer noopener"
								// disabled={!(formik.isValid && formik.dirty)}
								// type="submit"
								// onClick={formik.submitForm}
							>
								Catalogue
							</a> */}
							<button
								className="button-unfilled"
								onClick={() => navigate("/contact")}
							>
								Enquire Now
							</button>
						</div>
					</ProductWrapper1>
				</ProductTemplateWrapper>
				{isRefin && (
					<Refin
						poster={product.images[0].src}
						metafields={product.metafields}
					/>
				)}
			</ProductTemplateContainer>
		</Layout>
	);
};

export const query = graphql`
	query($id: String) {
		shopifyProduct(id: { eq: $id }) {
			...ShopifyProductFields
			totalInventory
			vendor
			priceRangeV2 {
				minVariantPrice {
					currencyCode
				}
			}
			tags
			variants {
				...ShopifyVariantFields
				price
				inventoryQuantity
				availableForSale
			}
		}
	}
`;

export default Product;
